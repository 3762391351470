/* eslint-disable guard-for-in */
import React from 'react';
import PropTypes from 'prop-types';
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import BitaCheckbox from './lib/MultiSelectCheckbox';
import './BitaMultiSelectArea.scss';
import Spinner from '../BitaSpinner/BitaSpinner';
import BitaButton from '../../bitaComponents/BitaButton/BitaButton';

const BitaMultiSelectArea = props => {
  let componente = false;
  const {
    data,
    handleChange,
    selectButtonsEnabled,
    allSelected,
    filterHelper,
    filterField,
    widgetData,
  } = props;
  // La data que recibe puede ser un array de opciones o un objeto con propiedades y el valor de cada propiedad ser un array con opciones
  const [allChecked, setAllChecked] = React.useState(allSelected);
  const [checkboxUpdate, setCheckBoxUpdate] = React.useState(false);
  const [dataReturnArray, setDataReturnArray] = React.useState([]);
  const [disabled, setDisabled] = React.useState(false);

  const initialData = Array.isArray(data ?? []) ? data ?? [] : [].concat(...Object.values(data));

  React.useEffect(() => {
    handleChange([]);
  }, []);

  React.useEffect(() => {
    const sendData = [...(dataReturnArray ?? []).filter(item => item.value)];
    handleChange(sendData);
  }, [dataReturnArray]);

  React.useEffect(() => {
    if (dataReturnArray && allSelected && Array.isArray(data))
      setDataReturnArray(data.map(field => ({ value: field.option, id: field.id })));
    if (dataReturnArray && !allSelected) setDataReturnArray([]);
  }, []);
  React.useEffect(() => {
    if (!widgetData && dataReturnArray.length > 0) {
      setDataReturnArray([]);
      setAllChecked(false);
    }
    if (widgetData) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [widgetData]);
  const toggleAllCheckboxes = status => {
    const dataALl = [].concat(...Object.values(data ?? [])).filter(item => !item?.hidden);
    setDataReturnArray(
      dataALl.map(option => {
        return { value: option.option, id: option.id };
      }),
    );
    setCheckBoxUpdate(prev => !prev);
    if (status !== allChecked) {
      setAllChecked(status);
    }
    if (!status) {
      if (dataReturnArray) setDataReturnArray([]);
    }
  };

  React.useEffect(() => {
    if (allChecked) {
      const dataALl = [].concat(...Object.values(data)).filter(item => !item?.hidden);
      if (dataALl.length !== dataReturnArray.length) {
        setDataReturnArray(
          dataALl.map(option => {
            return { value: option.option, id: option.id };
          }),
        );
      }
    }
  }, [data, allChecked]);
  React.useEffect(() => {
    if (Array.isArray(filterHelper)) {
      if (filterHelper.includes('Global')) {
        const dataALl = [].concat(...Object.values(data)).filter(item => !item?.hidden);
        if (dataALl.length !== dataReturnArray.length) {
          setDataReturnArray(
            dataALl.map(option => {
              return { value: option.option, id: option.id };
            }),
          );
        }
      } else {
        const filteredData = []
          .concat(...Object.values(data ?? {}))
          .filter(option => filterHelper.includes(option[filterField]) && !option?.hidden);
        setDataReturnArray(
          filteredData.map(option => {
            return { value: option.option, id: option.id };
          }),
        );
      }
    }
  }, [filterHelper, filterField, data]);

  const isChecked = option => {
    return widgetData && widgetData.length > 0
      ? widgetData.find(val => val.id === option.id)
      : dataReturnArray.find(val => val.id === option.id);
  };

  // Tratamiento de la data para retornar cuando la data inicial es un array
  const handleChangeArray = (checked, option) => {
    if (checked.value) {
      if (!dataReturnArray.find(val => val.id === option.id))
        setDataReturnArray(prev => [
          ...prev.filter(
            item => initialData?.find(optionItem => optionItem.id === item.id)?.option,
          ),
          { value: option.option, id: option.id },
        ]);
    } else {
      // eslint-disable-next-line vars-on-top
      const index = dataReturnArray.findIndex(val => val.id === option.id);
      if (index > -1) {
        const res = dataReturnArray;
        setDataReturnArray(res.filter(item => item.id !== option.id));
        handleChange(res.filter(item => item.id !== option.id));
      }
    }
  };

  // Tratamiento de la data que recibe
  // eslint-disable-next-line func-names
  if (Array.isArray(data)) {
    componente = [];
    // eslint-disable-next-line func-names
    componente = data.map(function(option) {
      let element;
      if (Array.isArray(option.value) && option.value.length) {
        element = (
          <div>
            <div style={{ fontWeight: '600', margin: '4px 0' }}>{option.option}</div>
            <div style={{ display: option?.hidden ? 'none' : '' }}>
              {option.value.length}
              {option.value.map((opt, k) => (
                <BitaCheckbox
                  key={
                    opt.id
                      ? `bita_checkbox_${opt.value}-${k}-${opt.id}`
                      : `bita_checkbox_${opt.value}-${k}`
                  }
                  disabled={disabled}
                  label={opt.option}
                  value={opt.value}
                  isChecked={isChecked(opt)}
                  handleChange={handleChangeArray}
                  onClick={() => setAllChecked(false)}
                  checkboxUpdate={checkboxUpdate}
                />
              ))}
            </div>
          </div>
        );
      } else {
        element = (
          <div
            key={option.id ? `key-${option.id}` : option.option}
            style={{ display: option?.hidden ? 'none' : '' }}
          >
            <BitaCheckbox
              disabled={disabled}
              label={option.option}
              value={option.value}
              isChecked={isChecked(option)}
              handleChange={checked => {
                handleChangeArray(checked, option);
              }}
              onClick={() => setAllChecked(false)}
              checkboxUpdate={checkboxUpdate}
            />
          </div>
        );
      }
      return element;
    });
  } else if (typeof data === 'object') {
    componente = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const propiedad in data) {
      componente.push(
        <div key={`propiedad_${propiedad}`} className="TitleOption">
          {propiedad.toUpperCase()}
        </div>,
      );
      data[propiedad].map(function recorrerArray(option) {
        componente.push(
          <div
            key={option.id ? `option-${propiedad}-${option.id}` : option.option}
            className="itemcheckbox"
            style={{ display: option?.hidden ? 'none' : '' }}
          >
            <BitaCheckbox
              disabled={disabled || option.disabled}
              labelClassName={props.labelClassName || ''}
              label={option.option}
              value={option.value}
              isChecked={isChecked(option)}
              checkboxUpdate={checkboxUpdate}
              onClick={() => setAllChecked(false)}
              handleChange={checked => {
                handleChangeArray(checked, option);
              }}
            />
          </div>,
        );
        return 0;
      });
    }
  } else {
    console.log('Data invalid');
  }

  return (
    <div className="w-full">
      <div
        className="w-full p-5 overflow-auto pb-5 MultiSelectArea "
        data-simplebar
        style={{ minHeight: '200px' }}
      >
        {' '}
        <div className={props.className}>
          {(componente.length === 0 &&
            'There are no constituents available for the filtered universe.') ||
            componente || <Spinner />}
        </div>
      </div>
      {selectButtonsEnabled && (
        <div>
          <BitaButton
            className={allChecked ? 'selectButtonsActive' : 'selectButtons'}
            width="124px"
            height="32px"
            secondary="secundary"
            onClick={() => toggleAllCheckboxes(true)}
          >
            Select All
          </BitaButton>
          <BitaButton
            className="selectButtons"
            width="124px"
            height="32px"
            secondary="secundary"
            onClick={() => toggleAllCheckboxes(false)}
          >
            Deselect All
          </BitaButton>
        </div>
      )}
    </div>
  );
};

BitaMultiSelectArea.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
  handleChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

BitaMultiSelectArea.defaultProps = {
  className: ' ',
};

export default BitaMultiSelectArea;
