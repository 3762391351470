/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import BitaCard from '../../components/BitaCard/BitaCard';
import GeneralWidget from '../../components/GeneralWidget/GeneralWidget';
import widgetStyles from '../../components/GeneralWidget/GeneralWidget.module.scss';
import PageLayout from '../../components/Layout/PageLayout';
import ConfigYAML from '../../config/ConfigYAML';
import { Icons } from '../../constants/icons';
import * as CommonActions from '../../state/actions/common-actions';
import * as ApiDataActions from '../../state/actions/api-data-actions';
import * as SummaryConstants from '../../constants/summary-constants';
import * as IndexConstants from '../../constants/index-operations-constants';
import { excludeUser } from '../../constants/exclude-modules-user';
import { cleanCache } from '../../state/actions/summary-actions';
import {
  hasValidUBS,
  hasValidIdBNP,
  hasValidMV_OPSOnly,
  hasValidMarketVectorSandboxOnly,
  hasValidBITASandboxOnly,
  hasValidMorganStanlyOnly,
} from '../../utils/utils';
import styles from './ProjectDashboard.module.scss';

const ProjectDashboard = () => {
  const dispatch = useDispatch();
  const [widgets, setWidgets] = useState([]);
  // eslint-disable-next-line
  const auth_token = useSelector(state => state.auth.token);
  const userdata = useSelector(state => state.auth.userdata);
  // eslint-disable-next-line
  const user_plan = {};
  // eslint-disable-next-line array-callback-return
  useSelector(state => state.auth.plan.modules).map(module => {
    user_plan[module.name] = module.status_plan;
  });
  // eslint-disable-next-line

  const showBasket =
    hasValidIdBNP(userdata.id_company) ||
    userdata.id_company === process.env.REACT_APP_BITA_ID ||
    userdata.id_company === process.env.REACT_APP_DEMO_COMPANY_ID ||
    hasValidUBS(userdata.id_company) ||
    hasValidMV_OPSOnly(userdata.id_company) ||
    hasValidMarketVectorSandboxOnly(userdata.id_company) ||
    hasValidBITASandboxOnly(userdata.id_company) ||
    hasValidMorganStanlyOnly(userdata.id_company);

  useEffect(() => {
    dispatch(ApiDataActions.setDataFields(auth_token));
    dispatch(ApiDataActions.setCustomDataFields(auth_token));

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    dispatch(CommonActions.resetSubheader());
    dispatch(CommonActions.appendSubheader('HOME'));
    const home = ConfigYAML.jsonPath('$.home');

    setWidgets(
      Object.values(home.widgets).map(widget => ({
        ...widget,
        components: Object.values(widget.components),
      })),
    );
  }, []);

  React.useEffect(() => {
    dispatch(ApiDataActions.setUniversesList(auth_token));
    dispatch(ApiDataActions.setMethodologiesList(auth_token));
    dispatch(ApiDataActions.setIndexesList(auth_token));
    dispatch(ApiDataActions.setClientDataFields(auth_token));
    dispatch(ApiDataActions.setBaskets(auth_token));
    dispatch(ApiDataActions.setAviableDataFields(auth_token));

    dispatch(
      ApiDataActions.setRiskModel(auth_token, `${userdata.first_name} ${userdata.last_name}`),
    );
    dispatch(ApiDataActions.setBenchmarkList(auth_token));
    dispatch({
      type: SummaryConstants.RESET,
    });
    dispatch({
      type: IndexConstants.RESET,
    });
    dispatch(cleanCache(auth_token));
    dispatch(
      ApiDataActions.setApiData({
        title: 'Optimized Index',
        data: {},
      }),
    );
    // eslint-disable-next-line
  }, []);

  return (
    <PageLayout>
      <div className={styles.widgetContainer}>
        {widgets.map(widget => (
          <div
            key={widget.title}
            className={`content ${widget.title} `}
            style={{
              display:
                hasValidUBS(userdata.id_company) && widget.title !== 'Basket Management Modules'
                  ? 'none'
                  : (widget.title === 'Basket Management Modules' ||
                      widget.title === 'Index Management Modules') &&
                    !showBasket
                  ? 'none'
                  : (hasValidIdBNP(userdata.id_company) ||
                      hasValidMV_OPSOnly(userdata.id_company) ||
                      hasValidMarketVectorSandboxOnly(userdata.id_company) ||
                      hasValidBITASandboxOnly(userdata.id_company) ||
                      hasValidMorganStanlyOnly(userdata.id_company)) &&
                    widget.title === 'Index Management Modules'
                  ? 'none'
                  : '',
            }}
          >
            <GeneralWidget title={widget.title} info={widget.description} />
            <div className={`${widgetStyles.cardContainer} cardcontainer `}>
              {widget.components.map(card => (
                <WidgetCardComponent
                  {...card}
                  key={card.title}
                  description={
                    hasValidMorganStanlyOnly(userdata.id_company) &&
                    card.title === 'Optimized Index Builder'
                      ? 'Coming soon...'
                      : card.description
                  }
                  color={
                    hasValidMorganStanlyOnly(userdata.id_company) &&
                    card.title === 'Optimized Index Builder'
                      ? 'secondary'
                      : widget?.color
                  }
                  status={
                    excludeUser[userdata?.company?.name] &&
                    excludeUser[userdata?.company?.name]?.[card?.title?.toLowerCase()]
                      ? 'HIDDEN'
                      : (card.title === 'Single Basket Submission' ||
                          card.title === 'Basket Update/Edition' ||
                          card.title === 'Basket to Index Conversion' ||
                          card.title === 'Bulk Basket Submission' ||
                          widget.title === 'Index Management Modules') &&
                        showBasket
                      ? 'ACTIVE'
                      : hasValidMorganStanlyOnly(userdata.id_company) &&
                        (card.title === 'Structured Index Builder' ||
                          card.title === 'Optimized Index Builder')
                      ? 'INACTIVE'
                      : user_plan[card.title.toUpperCase()]
                  }
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </PageLayout>
  );
};

const WidgetCardComponent = card => {
  const bitaCardProps = useMemo(
    () => ({
      icon: Icons[card.icon],
      title:
        card.title === 'Portfolio / Basket Calculation'
          ? 'Portfolio / Basket Backtesting'
          : card.title,
      text: card.description,
      cardType: 'BITACARD_MODULE',
      color: card.color,
    }),
    [card],
  );

  return (
    <div
      style={{
        opacity: card.status === 'ACTIVE' ? '1' : '0.5',
        pointerEvents: card.status === 'ACTIVE' ? 'auto' : 'none',
        display: card.status === 'HIDDEN' ? 'none' : 'block',
      }}
    >
      {' '}
      <Link
        key={card.title}
        to={card.status === 'ACTIVE' && card.color !== 'secondary' ? card.pathto : '/'}
      >
        <BitaCard {...bitaCardProps} />
      </Link>
    </div>
  );
};

export default ProjectDashboard;
