import React from 'react';
import { BUSINESS_DAYS } from './constants';

function truncateString(str, limit) {
  if (str.length > limit) {
    return `${str.substring(0, limit)}...`;
  }
  return str;
}

const CALCULATION_ZONE_MAPPING = {
  us_only: 'US / Eastern',
  us_europe: 'US / Europe',
  global: 'Global',
  europe_only: 'Europe only',
  eastern_apac: 'Eastern Asia Pacific',
};

// TODO Function to format date to yyyy-mm-dd (improve this because is the same function of UpdateBasket)
const formatDateString = dateString => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

const ItemRow = ({ label, value }) => (
  <div style={{ display: 'flex' }}>
    <div>{typeof label === 'string' ? label.replace(/_/g, ' ') : label}</div>
    <div>{typeof value === 'string' ? value.replace(/_/g, ' ') : value}</div>
  </div>
);

// Add a function to display the label
const getLabel = item => {
  if (item === 'symbol') return 'Ticker';
  if (item === 'withholding_tax_id') return 'withholding tax template';
  if (item === 'deployment_setup') return 'Launch Point';
  return item;
};

// Add a function to verify if renders the item or not
const shouldRenderItem = (item, calculationType, automaticallyAdjustDivisor) => {
  if (
    (item === 'cash_distribution' || item === 'application_point') &&
    calculationType === 'PRICE_RETURN'
  ) {
    return false;
  }

  // Only check automaticallyAdjustDivisor if it's explicitly set (UpdateBasket case)
  // If automaticallyAdjustDivisor is undefined (SubmitBasket case), we'll show the divisor
  if (item === 'divisor' && automaticallyAdjustDivisor === true) {
    return false;
  }

  return true;
};

const ShowPreview = ({ data, templateData, orgPrefix, launchDate, automaticallyAdjustDivisor }) => {
  // Add a function to display the value
  const getDisplayValue = (item, value) => {
    switch (item) {
      case 'start_week_day':
      case 'calculation_start_week_day':
        return BUSINESS_DAYS[value] ?? 'Monday';
      case 'end_week_day':
      case 'calculation_end_week_day':
        return BUSINESS_DAYS[value] ?? 'Friday';
      case 'description':
        return truncateString(value, 80);
      case 'calculation_zone':
        return CALCULATION_ZONE_MAPPING[value] ?? '';
      case 'launch_date':
        return formatDateString(launchDate);
      case 'symbol':
        return orgPrefix + value;
      case 'withholding_tax_id':
        return value
          ? templateData?.find(tax => tax.id === value)?.withholding_tax_name
          : 'BITA Withholding Taxes';
      default:
        return value;
    }
  };

  return Object.entries(data ?? {})
    .filter(([key, value]) => {
      return (
        typeof value === 'string' ||
        typeof value === 'number' ||
        key === 'divisor' ||
        (key === 'withholding_tax_id' && data?.calculation_type === 'NET_RETURN')
      );
    })
    .map(([item, value]) => {
      if (!shouldRenderItem(item, value, automaticallyAdjustDivisor)) return null;

      const label = getLabel(item);
      const displayValue = getDisplayValue(item, value);

      return <ItemRow key={item} label={label} value={displayValue} />;
    });
};

export default ShowPreview;
