import Moment from 'moment';
import { extendMoment } from 'moment-range';
// eslint-disable-next-line import/no-cycle
import { toast } from 'react-toastify';
import axios from '../Axios';
import { THERE_WAS_AN_ERROR_MESSAGE, UNEXPECTED_ERROR_MESSAGE } from '../constants/messages';
import * as IndexActions from '../state/actions/index-operations-actions';
import * as UniverseActions from '../state/actions/universe-operations-actions';
// eslint-disable-next-line import/no-cycle
import * as SummaryActions from '../state/actions/summary-actions';
import { setWaitingResponse } from '../state/actions/api-data-actions';
/* eslint-disable */
export const moment = extendMoment(Moment);

export const isDateBetweenRanges = (date, { startDate, endDate }) => {
  const range = moment().range(startDate, endDate);
  const d = moment(date);
  return range.contains(d);
};

export const isDevEnvironment = () => {
  return process.env.REACT_ENV;
};

export const roundUpPercentageString = number => {
  return Math.round((parseFloat(number) + Number.EPSILON) * 100) / 100;
};

export const isNumber = value =>
  !isNullUndefinedEmpty(value) && value !== false && value !== true && !isNaN(value);

export const isNullUndefinedEmpty = value => {
  return value === null || value === undefined || value === '';
};

export const isNumberBetween = (value, min, max) => {
  return isNumber(value) && min <= Number(value) && max >= Number(value);
};

export const minMaxTruncation = (value, min, max) => {
  if (isNumberBetween(value, min, max)) {
    return value;
  } else {
    if (!isNumber(value)) return min;
    else if (Number(value) > max) return max;
    else return min;
  }
};

export const isEmpty = obj => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const fromArrayLike = d =>
  Array.from({ ...d, length: Array.isArray(d) ? d.length : Object.keys(d).length });

export const getDateFromUTC = utc => utc.split('T')[0];

export const addOperationStateToProps = state => (group, subgroup, operation) => {
  const path = `${group}.${subgroup}.${operation}`;
  const storedSate = state[group][subgroup][operation];
  return {
    ...storedSate,
    summary: state.summary[path] || {},
  };
};

export const addOperationDispatchToProps = dispatch => (group, subgroup, operation) => {
  const path = `${group}.${subgroup}.${operation}`;
  return {
    updateAttribute: (attribute, newValue) => {
      switch (group) {
        case 'indexOperations':
          return dispatch(
            IndexActions.updateGeneric(subgroup, operation, { [attribute]: newValue }),
          );
        case 'universeBuilderOperations':
          return dispatch(
            UniverseActions.updateGeneric(subgroup, operation, { [attribute]: newValue }),
          );
      }
    },
    applyChangeToSummary: data => dispatch(SummaryActions.addEntryToSummary(path, data)),
    removeFromSummary: entry => dispatch(SummaryActions.removeEntryFromSummary(path, entry)),
    removeAllFromSummary: () => dispatch(SummaryActions.removeEntryFromSummary(path)),
    turnOnOff: on => {
      switch (group) {
        case 'indexOperations':
          return dispatch(IndexActions.updateGeneric(subgroup, operation, { on }));
        case 'universeBuilderOperations':
          return dispatch(UniverseActions.updateGeneric(subgroup, operation, { on }));
      }
      if (!on) {
        // si lo estamos apagando, borremos del summary
        dispatch(SummaryActions.removeEntryFromSummary(path));
      }
    },
  };
};
//METODO PARA TRANSFORMAR KEYS Y VALUES DE UN OBJETO A lowercase Y snake_case
export const transform = obj => {
  const keys = Object.keys(obj);
  const newObj = {};
  keys.forEach(propiedad => {
    if (typeof obj[propiedad] === 'object') {
      if (Array.isArray(obj[propiedad])) {
        newObj[propiedad.replace(/\s+/g, '_').toLowerCase()] = obj[propiedad];
      } else {
        const returnedObj = transform(obj[propiedad]);
        newObj[propiedad.replace(/\s+/g, '_').toLowerCase()] = returnedObj;
      }
    } else {
      newObj[propiedad.replace(/\s+/g, '_').toLowerCase()] = obj[propiedad]
        .replace(/\s+/g, '_')
        .toLowerCase();
    }
  });
  return newObj;
};

export const getAnalyzerCards = (token, type, page, name, sort_name, author) => {
  let url = `${process.env.REACT_APP_API_URL}/stats-esentials?type=${type}&page=${page}`;
  if (name) {
    url = `${url}&name=${name}`;
  }
  if (sort_name) {
    url = `${url}&sort_name=true`;
  }
  if (author) {
    url = `${url}&user=true`;
  }
  return axios.get(url, { headers: { Authorization: `Bearer ${token}` } });
};

export const clearCache = token => {
  const url = `${process.env.REACT_APP_API_URL}/clean-cache`;
  return axios.post(url, {}, { headers: { Authorization: `Bearer ${token}` } });
};

export const setDecimal = number => {
  return Number.parseFloat(number)
    .toFixed(2)
    .toString();
};

export const thousandFormat = number => String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

// Local helper function to parse JSON error message
const parseErrorMessage = message => {
  try {
    const jsonString = message.split('Error: ')[1];
    const jsonObject = JSON.parse(jsonString);
    return jsonObject.detail;
  } catch (error) {
    console.error('Failed to parse JSON:', error);
    return null;
  }
};

// Define default error messages as constants
const DEFAULT_ERROR_MESSAGES = {
  400: 'There was an error with status code 400. Please try again',
  401: 'There was an error with status code 401. Please try again',
  422: 'There was an error with status code 422. Please try again',
  500: 'There was an error with status code 500. Please try again',
};

export const toastInterceptor = message => {
  const showErrorToast = statusCode => {
    console.info('message ', message?.response?.data?.error);
    toast.error(message?.response?.data?.error || DEFAULT_ERROR_MESSAGES[statusCode]);
  };

  switch (message?.response?.status) {
    case 201:
      toast(message?.data?.message || 'Operation was successful');
      break;

    case 400:
    case 401:
    case 422:
    case 500:
      showErrorToast(message.response.status);
      break;

    default:
      toast.error(message?.message ?? UNEXPECTED_ERROR_MESSAGE);
      break;
  }
};

export const versionIndexName = (name, list) => {
  const reggex = /\((\d+)\)/;
  const lastStrings = name.substr(name.length - 3);
  const validateName = reggex.test(lastStrings) ? name.substring(0, name.length - 4) : name;

  const filterName = name => {
    const splitName = name?.split(validateName);
    if (splitName?.[0] === '') {
      if ((reggex.test(splitName[1]) || splitName[1] === '') && splitName[2] === undefined) {
        return true;
      }
      return false;
    }
    return false;
  };
  const nameVersion = list.filter(i => filterName(i.name)).length;
  if (nameVersion > 0) {
    return `${validateName} (${nameVersion})`;
  }
  return validateName;
};
export const capitalizeText = text => {
  return text.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
};

export const data_attribute_format = att => {
  return att.replace(/\s/g, '_').toLowerCase();
};

export const remove_option = condition => {
  /*if (process.env.REACT_APP_API_URL !== 'https://api-test.bitacore.com/v1') {
    return true;
  } else {*/
  return condition;
};

export const convertToNumber = value => {
  return Number(value.replace(',', ''));
};

export const formatMarketCapColumn = props => {
  const newParsedConstituents = props.constituents;
  newParsedConstituents.map(item => {
    const newMarketCap = item['Market Cap']?.replace('M', '').replaceAll(' ', '');
    item['Market Cap'] = newMarketCap;
    // change the name of the key to Market Capitalization in USD
    item['Market Cap in USD Mn.'] = item['Market Cap'];
    // convert to number the value of market cap
    item['Market Cap in USD Mn.'] = Number(item['Market Cap in USD Mn.']);
    delete item['Market Cap'];
    return item;
  });
  return newParsedConstituents;
};

export const formatFreefloatMarketCapColumn = data => {
  const newParsedConstituents = data;
  newParsedConstituents?.map(item => {
    const newMarketCap = item['Free Float Marketcap']?.replace('M', '').replaceAll(' ', '');
    item['Free Float Marketcap'] = newMarketCap;
    // change the name of the key to Market Capitalization in USD
    item['Free Float Marketcap in USD Mn.'] = item['Free Float Marketcap'];
    // convert to number the value of market cap
    item['Free Float Marketcap in USD Mn.'] = Number(item['Free Float Marketcap in USD Mn.']);
    delete item['Free Float Marketcap'];
    return item;
  });
  return newParsedConstituents;
};

export const riskControlInterestRate = data => {
  if (data.interest_rate.value === 'LIBOR') {
    return data;
  }
  if (data.interest_rate.value === 'EURIBOR') {
    return { interest_rate: data.interest_rate, interest_timeframe: data.interest_timeframe };
  }
  return { interest_rate: data.interest_rate };
};

/**
 * Validates that the email has a valid BITA domain ('bita.io' or 'bitadata.com').
 * @param {string} email The email address to validate.
 * @returns {boolean} True if the email has a valid BITA domain, false otherwise.
 */
export const hasValidBitaDomain = email => {
  if (email) {
    const domains = process?.env?.REACT_APP_EMAIL_DOMAIN?.split(',') || [];
    const emailDomain = email?.split('@')[1];

    return domains.some(domain => emailDomain.includes(domain.trim()));
  }

  return false;
};

export const hasValidIdBNP = id => {
  const valid = id === process?.env?.REACT_APP_BNP_ID;
  return valid;
};

export const hasValidUBS = id => {
  return (
    id === process?.env?.REACT_APP_UBS_ID ||
    id === process?.env?.REACT_APP_UBS_APAC_ID ||
    id === process?.env?.REACT_APP_ULTUMUS_ID ||
    id === process?.env?.REACT_APP_ULTUMUS_UAT_ID
  );
};

export const hasValidUBSOnly = id => {
  return id === process?.env?.REACT_APP_UBS_ID || id === process?.env?.REACT_APP_UBS_APAC_ID;
};

export const hasValidUBSAPACOnly = id => {
  return id === process?.env?.REACT_APP_UBS_APAC_ID;
};

export const hasValidMV_PDOnly = id => {
  return id === process?.env?.REACT_APP_MV_PD;
};

export const hasValidMV_OPSOnly = id => {
  return id === process?.env?.REACT_APP_MV_OPS;
};

export const hasValidMarketVectorSandboxOnly = id => {
  return id === process?.env?.REACT_APP_MV_SB;
};

export const hasValidBITASandboxOnly = id => {
  return id === process?.env?.REACT_APP_BITA_SB;
};

export const hasValidMorganStanlyOnly = id => {
  return id === process?.env?.REACT_APP_MORGAN_STANLEY;
};
