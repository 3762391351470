export const URL_CONSTANTS = {
  WITHHOLDING_TAX: `${process.env.REACT_APP_API_URL}/withholding-tax`,
  ESG_TOPICS_GET_URL: `${process.env.REACT_APP_API_URL}/esg-topics/{{ID}}?universe={{UNIVERSE}}`,
  // Url para cuando se crea universo por white canvas
  ESG_TOPICS_GET_URL_WHITE_CANVAS: `${process.env.REACT_APP_API_URL}/esg-topics/{{ID}}`,
  EXCHANGES_GET_URL: `${process.env.REACT_APP_API_URL}/exchanges`,
  RISKMODELS_GET_URL: `${process.env.REACT_APP_API_URL}/risk-models`,
  SECTORS_GET_URL: `${process.env.REACT_APP_API_URL}/gics/{{ID}}?universe={{UNIVERSE}}`,
  // Url para cuando se crea universo por white canvas
  SECTORS_GET_URL_WHITE_CANVAS: `${process.env.REACT_APP_API_URL}/gics/{{ID}}`,
  SECURITIES_GET_URL: `${process.env.REACT_APP_API_URL}/securities?universe={{UNIVERSE}}`,
  THEMES_GET_URL: `${process.env.REACT_APP_API_URL}/bita-thematic?universe={{UNIVERSE}}&type={{TYPE}}`,
  GEOGRAPHY_GET_URL: {
    primary_list: `${process.env.REACT_APP_API_URL}/primary-list?universe={{ID}}`,
    country_list: `${process.env.REACT_APP_API_URL}/country-list?universe={{ID}}`,
    exchanges_list: `${process.env.REACT_APP_API_URL}/exchanges?universe={{ID}}`,
    country_headquarter: `${process.env.REACT_APP_API_URL}/country-headquarter?universe={{ID}}`,
    country_bita: `${process.env.REACT_APP_API_URL}/country-bita?universe={{ID}}`,
    country_bita_group_by_liquidity: `${process.env.REACT_APP_API_URL}/country-bita?universe={{ID}}&liquidity={{LIQUIDITY}}&value={{VALUE}}&countries={{COUNTRIES}}`,
    country_bita_group_by_size: `${process.env.REACT_APP_API_URL}/country-bita?universe={{ID}}&size={{SIZE}}&value={{VALUE}}&countries={{COUNTRIES}}`,
    primary_list_group_by_liquidity: `${process.env.REACT_APP_API_URL}/primary-list?universe={{ID}}&liquidity={{LIQUIDITY}}&value={{VALUE}}&countries={{COUNTRIES}}`,
    primary_list_group_by_size: `${process.env.REACT_APP_API_URL}/primary-list?universe={{ID}}&size={{SIZE}}&value={{VALUE}}&countries={{COUNTRIES}}`,
    country_list_group_by_liquidity: `${process.env.REACT_APP_API_URL}/country-list?universe={{ID}}&liquidity={{LIQUIDITY}}&value={{VALUE}}&countries={{COUNTRIES}}`,
    country_list_group_by_size: `${process.env.REACT_APP_API_URL}/country-list?universe={{ID}}&size={{SIZE}}&value={{VALUE}}&countries={{COUNTRIES}}`,
    country_headquarter_group_by_liquidity: `${process.env.REACT_APP_API_URL}/country-headquarter?universe={{ID}}&liquidity={{LIQUIDITY}}&value={{VALUE}}&countries={{COUNTRIES}}`,
    country_headquarter_group_by_size: `${process.env.REACT_APP_API_URL}/country-headquarter?universe={{ID}}&size={{SIZE}}&value={{VALUE}}&countries={{COUNTRIES}}`,
  },
  // Url para cuando se crea universo por white canvas
  GEOGRAPHY_GET_URL_WHITE_CANVAS: {
    primary_list: `${process.env.REACT_APP_API_URL}/primary-list`,
    country_list: `${process.env.REACT_APP_API_URL}/country-list`,
    exchanges_list: `${process.env.REACT_APP_API_URL}/exchanges`,
    country_headquarter: `${process.env.REACT_APP_API_URL}/country-headquarter`,
    country_bita: `${process.env.REACT_APP_API_URL}/country-bita`,
    country_bita_group_by_liquidity: `${process.env.REACT_APP_API_URL}/country-bita?liquidity={{LIQUIDITY}}&value={{VALUE}}&countries={{COUNTRIES}}`,
    country_bita_group_by_size: `${process.env.REACT_APP_API_URL}/country-bita?size={{SIZE}}&value={{VALUE}}&countries={{COUNTRIES}}`,
    primary_list_group_by_liquidity: `${process.env.REACT_APP_API_URL}/primary-list?liquidity={{LIQUIDITY}}&value={{VALUE}}&countries={{COUNTRIES}}`,
    primary_list_group_by_size: `${process.env.REACT_APP_API_URL}/primary-list?size={{SIZE}}&value={{VALUE}}&countries={{COUNTRIES}}`,
    country_list_group_by_liquidity: `${process.env.REACT_APP_API_URL}/country-list?liquidity={{LIQUIDITY}}&value={{VALUE}}&countries={{COUNTRIES}}`,
    country_list_group_by_size: `${process.env.REACT_APP_API_URL}/country-list?size={{SIZE}}&value={{VALUE}}&countries={{COUNTRIES}}`,
    country_headquarter_group_by_liquidity: `${process.env.REACT_APP_API_URL}/country-headquarter?liquidity={{LIQUIDITY}}&value={{VALUE}}&countries={{COUNTRIES}}`,
    country_headquarter_group_by_size: `${process.env.REACT_APP_API_URL}/country-headquarter?size={{SIZE}}&value={{VALUE}}&countries={{COUNTRIES}}`,
  },
  USERMANAGER_GET_URL: `${process.env.REACT_APP_API_URL}/users-company`,
  INSTRUMENTSELECTION_GET_URL: {
    etfs_list: `${process.env.REACT_APP_API_URL}/etfs`,
    indexes_list: `${process.env.REACT_APP_API_URL}/indexes`,
  },
  CREATE_USER_GET_URL: `${process.env.REACT_APP_API_URL}/user`,
  UNIVERSE_FILTER_GET_URL: `${process.env.REACT_APP_API_URL}/filter-universe`,
  UNIVERSE_ALL_FILTERS_GET_URL: `${process.env.REACT_APP_API_URL}/filter-universe-all`,
  SAVE_UNIVERSE_URL: `${process.env.REACT_APP_API_URL}/save-universe`,
  CONSTITUENTS_TAB_URL: `${process.env.REACT_APP_API_URL}/preview-universe?type=present`,
  RUN_BACKTEST_URL: `${process.env.REACT_APP_API_URL}/run-backtest`, // TODO RECORDAR ARREGLAR
  RUN_OPTIMIZED_BACKTEST_URL: `${process.env.REACT_APP_API_URL}/run-backtest-optimized`,
  RUN_STRUCTURED_INDEX_BACKTEST_URL: `${process.env.REACT_APP_API_URL}/run-backtest-structured`,
  RUN_BASKET_INDEX_URL: `${process.env.REACT_APP_API_URL}/run-backtest-basket`,
  RUN_FACTSHEET: `${process.env.REACT_APP_API_URL}/run-factsheet`,
  RUN_REBALANCING_BACKTEST_URL: `${process.env.REACT_APP_API_URL}/run-backtest-rebalancing`,
  SAVE_FACTSHEET: `${process.env.REACT_APP_API_URL}/save-factsheet`,
  SAVE_INDEX: `${process.env.REACT_APP_API_URL}/index`,
  SAVE_OR_UPDATE_STATS: `${process.env.REACT_APP_API_URL}/stats`,
  UNIVERSE_SELECTED_URL: `${process.env.REACT_APP_API_URL}/load-last-revisions?universe={{UNIVERSE}}`,
  UNIVERSE_SELECTED_FIRST_REVISION_URL: `${process.env.REACT_APP_API_URL}/first-revision?universe={{UNIVERSE}}`,
  SECURITY_TYPE_GET_URL: `${process.env.REACT_APP_API_URL}/securities-type?universe={{UNIVERSE}}`,
  UNIVERSE_REVISION_DATE: `${process.env.REACT_APP_API_URL}/get-days-revisions`,
  SAVE_DATE_FIELD: `${process.env.REACT_APP_API_URL}/custom-data-fields`,
  UNIVERSE_BUFFERED_RANK_URL: `${process.env.REACT_APP_API_URL}/buffer-rak-filter?lower={{LOWER}}&upper={{UPPER}}&target={{TARGET}}&is_ascending={{is_ascending}}`,
  ACE_MIC_ALL_URL: `${process.env.REACT_APP_API_URL}/ace/basket/mic/all`,
  GET_BASKET_BY_TICKER: `${process.env.REACT_APP_API_URL}/ace/basket/{{BASKET}}`,
  GETLIVEBASKET: `${process.env.REACT_APP_API_URL}/ace/basket`,
  UPLOADFILELIVEBASKET: `${process.env.REACT_APP_API_URL}/ace/basket/upload-file`,
  SUBMITLIVEBASKET: `${process.env.REACT_APP_API_URL}/ace/basket/submit`,
  UPDATELIVEBASKET: `${process.env.REACT_APP_API_URL}/ace/basket/update`,
  COVERAGE_REQUEST_URL: `${process.env.REACT_APP_API_URL}/ace/basket/send-email-request-coverage`,
  COVERAGE_REQUEST_UPDATE_URL: `${process.env.REACT_APP_API_URL}/ace/basket/request-coverage`,
  CREATE_WITHHOLDING_TAX: `${process.env.REACT_APP_API_URL}/withholding-tax`,
  UPDATE_WITHHOLDING_TAX: `${process.env.REACT_APP_API_URL}/withholding-tax`,
  CONSULT_INSTRUMENT: `${process.env.REACT_APP_API_URL}/ace/basket/mapper/{{TYPE}}/{{VALUE}}`,
  CONSULT_EXIST_BASKET: `${process.env.REACT_APP_API_URL}/ace/basket/exist/{{BASKET}}`,
  UPLOAD_BACKTEST_BASKET: `${process.env.REACT_APP_API_URL}/ace/basket/upload-backtest/{{BASKET}}`,
};
